<template>
	<div class="">
		<div class="gg-container">
			<div class="search-container">
				<div class="search-container-fn-input">
					<label>关键词：</label>
					<el-input
						size="small"
						placeholder="请输入题干信息"
						prefix-icon="el-icon-search"
						v-model="searchParams.keywords"
						clearable
						@change="getList"
					>
					</el-input>
				</div>
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
					@click="getList('restPage')"
				>
					<span>查找</span>
				</el-button>
				<div class="search-container-fn-input" style="margin-left: auto;">
					<el-button
						size="mini"
						type="primary"
						@click="handleAddQuestion"
						icon="el-icon-circle-plus"
						style="padding: 7px 8px;">
						添加试题
					</el-button>
					<el-button
						size="mini"
						type="danger"
						@click="handleBatchDelQuestion"
						icon="el-icon-error"
						style="padding: 7px 8px;margin-left: 5px;">
						批量删除
					</el-button>
				</div>
			</div>
			
			<!--选择题库区域-->
			<div class="choice-question-container">
				<span style="font-size: 13px; color: #777; font-weight: bolder" class="mgr-10">题型：</span>
				<el-button
					v-for="(item, index) in choiceQuestions"
					:key="index"
					class="mgr-10"
					:class="isChoiceQuestion === item ? 'isActiveChoiceQuestion' : '' "
					style="font-size: 13px; color: #333; font-weight: bolder"
					type="text"
					@click="handleIsActiveChoiceQuestion(item)"
				>{{item}}
				</el-button>
			</div>
			<div class="choice-status-container">
				<span style="font-size: 13px; color: #777; font-weight: bolder" class="mgr-10">状态：</span>
				<el-button
					class="mgr-10"
					:class="isChoiceStatus === '启用' ? 'isActiveChoiceQuestion' : '' "
					style="font-size: 13px; color: #333; font-weight: bolder"
					type="text"
					@click="handleIsActiveChoiceStatus('启用')"
				>启用
				</el-button>
				<el-button
					class="mgr-10"
					:class="isChoiceStatus === '禁用' ? 'isActiveChoiceQuestion' : '' "
					style="font-size: 13px; color: #333; font-weight: bolder"
					type="text"
					@click="handleIsActiveChoiceStatus('禁用')"
				>禁用
				</el-button>
			</div>
		</div>
		
		<!-- 题库列表 -->
		<div v-loading="listLoading" style="position: relative; z-index: 100; height: 600px; overflow-y: auto">
			<div class="question-list" v-for="(item, index) in questionList" :key="item.id">
				
				<!--单选-->
				<el-card class="box-card mgb-10 pat-10 pdb-10 single-choice" v-show=" '单选' === item.type_name ">
					<div class="question-title">
						<el-checkbox style="color: #333; font-weight: bolder" @change="handleChoiceQuestion(item.id)">
							{{index+1 + (listQuery.page-1)*listQuery.limit}}. {{item.title}} (ID：{{item.id}})
						</el-checkbox>
						<el-tag effect="plain" size="mini" class="mgl-70" style="height: 19px">单选</el-tag>
					</div>
					<div class="question-answer">
						<el-radio-group v-model="radio">
							<el-radio :label="option.id" style="color: #333" v-for="option in item.options_value" :key="option.id">
								{{option.name}}
							</el-radio>
						</el-radio-group>
					</div>
					
					<div class="question-button">
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							type="primary"
							@click="handleEditQuestion('单选', item)">
							<i class="el-icon-edit" style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">编辑</span>
						</el-button>
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							:type=" item.status === 0 ? 'info'  : 'success'"
							@click="handleExchangeStatusQuestion(item)">
							<i :class="item.status === 0 ? 'iconfont icon-jinyong' : 'el-icon-check'  " style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">{{item.status === 1 ? '启用' : '禁用' }}</span>
						</el-button>
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							type="danger"
							@click="handleDeleteQuestion(item)">
							<i class="el-icon-delete" style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">删除</span>
						</el-button>
					</div>
				</el-card>
				
				<!--填空-->
				<el-card class="box-card mgb-10 pat-10 pdb-10 gap-filling" v-show=" '填空' === item.type_name ">
					<div class="question-title">
						<el-checkbox style="color: #333; font-weight: bolder" @change="handleChoiceQuestion(item.id)">
							{{index+1 + (listQuery.page-1)*listQuery.limit}}. {{item.title}} (ID：{{item.id}})
						</el-checkbox>
						<el-tag effect="plain" size="mini" class="mgl-70" style="height: 19px">填空</el-tag>
					</div>
					<div class="question-answer" style="font-size: 14px">
						{{item.format_content}}
					</div>
					
					<div class="question-button">
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							type="primary"
							@click="handleEditQuestion('填空', item)">
							<!-- <i class="el-icon-edit" style="font-size: 13px"/> -->
							<span style="margin-left: 5px; font-size: 12px">编辑</span>
						</el-button>
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							:type=" item.status === 0 ? 'info'  : 'success'"
							@click="handleExchangeStatusQuestion(item)">
							<i :class="item.status === 0 ? 'iconfont icon-jinyong' : 'el-icon-check'  " style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">{{item.status === 1 ? '启用' : '禁用' }}</span>
						</el-button>
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							type="danger"
							@click="handleDeleteQuestion(item)">
							<i class="el-icon-delete" style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">删除</span>
						</el-button>
					</div>
				
				</el-card>
				
				<!--多选-->
				<el-card class="box-card mgb-10 pat-10 pdb-10 multiple-choice" v-show=" '多选' === item.type_name ">
					<div class="question-title">
						<el-checkbox style="color: #333; font-weight: bolder"
												 @change="handleChoiceQuestion(item.id)">
							{{index+1 + (listQuery.page-1)*listQuery.limit}}. {{item.title}} (ID：{{item.id}})
						</el-checkbox>
						<el-tag effect="plain" size="mini" class="mgl-70" style="height: 19px">多选</el-tag>
					</div>
					<div class="question-answer">
						<el-checkbox-group v-model="multipleChoiceAnswers" @change="handleMultipleChoiceAnswer">
							<el-checkbox :label="option.id" style="color: #333" v-for="option in item.options_value" :key="option.id">
								{{option.name}}
							</el-checkbox>
						</el-checkbox-group>
					</div>
					
					<div class="question-button">
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							type="primary"
							@click="handleEditQuestion('多选', item)">
							<i class="el-icon-edit" style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">编辑</span>
						</el-button>
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							:type=" item.status === 0 ? 'info'  : 'success'"
							@click="handleExchangeStatusQuestion(item)">
							<i :class="item.status === 0 ? 'iconfont icon-jinyong' : 'el-icon-check'  " style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">{{item.status === 1 ? '启用' : '禁用' }}</span>
						</el-button>
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							type="danger"
							@click="handleDeleteQuestion(item)">
							<i class="el-icon-delete" style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">删除</span>
						</el-button>
					</div>
				
				</el-card>
				
				<!--病历图片-->
				<el-card class="box-card mgb-10 pat-10 pdb-10 patient-case-image" v-show=" '图片' === item.type_name ">
					<div class="question-title">
						<el-checkbox style="color: #333; font-weight: bolder"
												 @change="handleChoiceQuestion(item.id)">
							{{index+1 + (listQuery.page-1)*listQuery.limit}}. {{item.title}} (ID：{{item.id}})
						</el-checkbox>
						<el-tag effect="plain" size="mini" class="mgl-70" style="height: 19px">图片</el-tag>
					</div>
					<div class="question-answer" style="font-size: 14px; display: flex;align-items: center">
						<customUpload
							style="margin-right: 20px"
							v-for="img in item.img_count"
							:key="img"
							:fileUrl=" '' "
							@on-success="(file) => onSuccess(file, 'user_id')"
							width="100px" height="70px"></customUpload>
						<span class="mgl-30" style="font-size: 14px; color: #777; font-weight: bolder;">上传病历, 检查报告或患处照片(最多上传{{item.img_count}}张)</span>
					</div>
					
					<div class="question-button">
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							type="primary"
							@click="handleEditQuestion('图片', item)">
							<i class="el-icon-edit" style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">编辑</span>
						</el-button>
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							:type=" item.status === 0 ? 'info'  : 'success'"
							@click="handleExchangeStatusQuestion(item)">
							<i :class="item.status === 0 ? 'iconfont icon-jinyong' : 'el-icon-check'  " style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">{{item.status === 1 ? '启用' : '禁用' }}</span>
						</el-button>
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							type="danger"
							@click="handleDeleteQuestion(item)">
							<i class="el-icon-delete" style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">删除</span>
						</el-button>
					</div>
				
				</el-card>
				
				<!--简答-->
				<el-card class="box-card mgb-10 pat-10 pdb-10 short-answer" v-show=" '简答' === item.type_name ">
					<div class="question-title">
						<el-checkbox style="color: #333; font-weight: bolder" @change="handleChoiceQuestion(item.id)">
							{{index+1 + (listQuery.page-1)*listQuery.limit}}. {{item.title}} (ID：{{item.id}})
						</el-checkbox>
						<el-tag effect="plain" size="mini" class="mgl-70" style="height: 19px">简答</el-tag>
					</div>
					<div class="question-answer" style="font-size: 14px">
						<el-input type="textarea" v-model="shortAnswerDesc" placeholder="请填写主诉" :rows="3"></el-input>
					</div>
					
					<div class="question-button">
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							type="primary"
							@click="handleEditQuestion('简答', item)">
							<i class="el-icon-edit" style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">编辑</span>
						</el-button>
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							:type=" item.status === 0 ? 'info'  : 'success'"
							@click="handleExchangeStatusQuestion(item)">
							<i :class="item.status === 0 ? 'iconfont icon-jinyong' : 'el-icon-check'  " style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">{{item.status === 1 ? '启用' : '禁用' }}</span>
						</el-button>
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							type="danger"
							@click="handleDeleteQuestion(item)">
							<i class="el-icon-delete" style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">删除</span>
						</el-button>
					</div>
				
				</el-card>
				
				<!--单选+简答-->
				<el-card class="box-card mgb-10 single-choice-short-answer" v-show=" '单选+简答' === item.type_name ">
					<div class="question-title">
						<el-checkbox style="color: #333; font-weight: bolder"
												 @change="handleChoiceQuestion(item.id)">
							{{index+1 + (listQuery.page-1)*listQuery.limit}}. {{item.title}} (ID：{{item.id}})
						</el-checkbox>
						<el-tag effect="plain" size="mini" class="mgl-70" style="height: 19px">单选+简答</el-tag>
					</div>
					<div class="question-answer" style="font-size: 14px">
						<el-radio-group v-model="singleChoiceShortAnswerRadio" class="mgb-20">
							<el-radio :label="option.id" style="color: #333" v-for="option in item.options_value" :key="option.id">
								{{option.name}}
							</el-radio>
						</el-radio-group>
						<el-input type="textarea" v-model="item.content" placeholder="请填写清楚" :rows="3">{{item.content}}</el-input>
					</div>
					<div class="question-button" style="margin-bottom: 20px">
						<el-button
							style="padding:7px 8px;"
							size="mini"
							type="primary"
							@click="handleEditQuestion('单选+简答', item)"
							icon="el-icon-edit">
							<!-- <i class="el-icon-edit" style="font-size: 13px"/> -->
							<!-- <span style="margin-left: 5px; font-size: 12px">编辑</span> -->
							编辑
						</el-button>
						<el-button
							style="padding: 7px 8px;margin-left: 5px;"
							size="mini"
							:type=" item.status === 0 ? 'info'  : 'success'"
							@click="handleExchangeStatusQuestion(item)">
							<i :class="item.status === 0 ? 'iconfont icon-jinyong' : 'el-icon-check'  " style="font-size: 13px"/>
							<span>{{item.status === 1 ? '启用' : '禁用' }}</span>
						</el-button>
						<el-button
							style="padding-left: 5px; padding-right: 5px"
							size="mini"
							type="danger"
							@click="handleDeleteQuestion(item)">
							<i class="el-icon-delete" style="font-size: 13px"/>
							<span style="margin-left: 5px; font-size: 12px">删除</span>
						</el-button>
					</div>
				
				</el-card>
			
			</div>
			<pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
									@pagination="getList"/>
		</div>
		
		
		<!--添加题目弹出框-->
		<addQuestionDialogs v-if="isActiveAddQuestionDialogs"
												:isActiveAddQuestionDialogs.sync="isActiveAddQuestionDialogs"
												:questionType="questionType"
												:editFormData="editFormData"
												:isEditStatus.sync="isEditStatus"
												@getList="getList">
		
		</addQuestionDialogs>
	</div>
</template>

<script>
import customUpload from "@/components/customUpload";
import addQuestionDialogs from './components/AddQuestionDialogs'
import {
  getQuestionOptionsList,
  putQuestionOptionsStatus,
  deleteQuestionDelete
} from '@/api/cdm/cdm-healthFile.js';
import Pagination from '@/components/Pagination/index';

export default {
  name: "QuestionBank",
  components: {
    Pagination,
    customUpload,
    addQuestionDialogs
  },
  created () {
    this.getList()
  },
  data () {
    return {
      questionList: [],
      questionType: '单选',	//1 单选  2多选 3图片 4 简答 5 填空  6 单选+简答
      searchParams: {
        type: '',
        status: '',
        keywords: ''	// 标题
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      choiceQuestions: ['全选', '单选', '多选', '图片', '简答', '填空', '单选+简答'],
      checkChoiceQuestions: [], // 选中的问题列表,做批量删除使用
      isChoiceQuestion: '全选',
      isChoiceStatus: '启用', // 启用, 禁用
      checked: false,
      radio: 0,
      multipleChoiceAnswers: [],
      patientCaseImages: [],
      shortAnswerDesc: '',
      singleChoiceShortAnswerRadio: 0,
      singleChoiceShortAnswerDesc: '',
      isActiveAddQuestionDialogs: false,
      editFormData: {}, // 编辑传入弹出框的数据
      isEditStatus: false // 判断按钮是否是编辑按钮
    }
  },
  methods: {
    getList (rest) {
      if (rest === 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true

      let params = { ...this.searchParams }
      params.page = this.listQuery.page
      params.page_size = this.listQuery.limit
      getQuestionOptionsList(params).then(response => {
        let data = response
        if (data.code === 200) {
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = Number(_data.per_page)
          this.questionList = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    _putQuestionOptionsStatus (id, data) {
      this.listLoading = true
      putQuestionOptionsStatus(id, data).then(response => {
        let data = response
        if (data.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: data.msg,
            type: 'error'
          })
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    _deleteQuestionDelete (ids) {
      deleteQuestionDelete(ids).then(response => {
        this.listLoading = true
        let data = response
        if (data.code == 200) {
          setTimeout(() => {
            this.getList()
          })
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: data.msg
          })
        }
        this.listLoading = false
      }).catch((error) => {
        console.log(error)
        this.$message({
          type: 'error',
          message: '网络故障'
        })
        this.listLoading = false
      })
    },

    handleAddQuestion () {
      this.isActiveAddQuestionDialogs = true
    },
    handleEditQuestion (questionType, item) {
      this.questionType = questionType
      this.isActiveAddQuestionDialogs = true
      this.editFormData = item
      this.isEditStatus = true
    },
    handleExchangeStatusQuestion (item) {
      console.log(item)
      if (item.status === 1) {
        item.status = 0
      } else {
        item.status = 1
      }
      this._putQuestionOptionsStatus(item.id, { status: item.status })
    },
    handleBatchDelQuestion () {
      if (this.checkChoiceQuestions.length === 0) {
        this.$message({
          type: 'warning',
          message: '没有选中需要删除的题目!'
        })
      } else {
        console.log(this.checkChoiceQuestions)
        this.$confirm('此操作将永久删除该试题, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this._deleteQuestionDelete(this.checkChoiceQuestions.join(','))
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }
    },
    handleDeleteQuestion (item) {
      console.log(item)
      this.$confirm('此操作将永久删除该试题, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this._deleteQuestionDelete(item.id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleIsActiveChoiceQuestion (choice) {
      console.log(choice)
      this.isChoiceQuestion = choice
      // 1 单选  2多选 3图片 4 简答 5 填空  6 单选+简答
      if (choice === '全选') {
        this.searchParams.type = ''
      } else {
        this.searchParams.type = this.choiceQuestions.indexOf(choice)
      }
      this.getList()
    },
    handleIsActiveChoiceStatus (status) {
      this.isChoiceStatus = status
      if (this.isChoiceStatus === '启用') {
        this.searchParams.status = 1
      } else {
        this.searchParams.status = 0
      }
      this.getList()
    },
    handleChoiceQuestion (id) {
      console.log(id)
      const index = this.checkChoiceQuestions.indexOf(id)
      if (index === -1) {
        this.checkChoiceQuestions.push(id)
      } else {
        this.checkChoiceQuestions.splice(index, 1)
      }
    },
    handleMultipleChoiceAnswer (value) {
      console.log(value)
    },
    onSuccess (file, name) {
      this.patientCaseImages.push(file);
    },
  }
}
</script>

<style scoped lang="scss">
	.header {
		display: flex;
		align-items: center;
		height: 26px;
		margin-bottom: 10px;
		
		i {
			width: 25px;
			height: 25px;
			background-color: #2632fb;
			color: white;
			line-height: 25px;
			text-align: center;
			margin-right: 8px;
			border-radius: 2px;
		}
	}
	
	.mgb-10 {
		margin-bottom: 10px;
	}
	
	.mgb-20 {
		margin-bottom: 20px;
	}
	
	.mgr-10 {
		margin-right: 10px;
	}
	
	.mgr-30 {
		margin-right: 30px;
	}
	
	.mgl-30 {
		margin-left: 30px;
	}
	
	.mgl-70 {
		margin-left: 70px;
	}
	
	.pat-10 {
		padding-top: 10px;
	}
	
	.pdb-10 {
		padding-bottom: 10px;
	}
	
	.isActiveChoiceQuestion {
		color: #2362FB !important;
		font-weight: bolder
	}
	
	.question-list {
		overflow-y: auto;
		// height: 600px;
	}
	
	/*滚动条样式*/
	::-webkit-scrollbar {
		width: 7px;
		height: 5px;
		border-radius: 15px;
		-webkit-border-radius: 15px;
	}
	
	::-webkit-scrollbar-track-piece {
		background-color: #ffff;
		border-radius: 15px;
		-webkit-border-radius: 15px;
	}
	
	::-webkit-scrollbar-thumb:vertical {
		height: 5px;
		background-color: rgba(144, 147, 153, 0.5);
		border-radius: 15px;
		-webkit-border-radius: 15px;
	}
	
	::-webkit-scrollbar-thumb:horizontal {
		width: 7px;
		background-color: rgba(144, 147, 153, 0.5);
		border-radius: 15px;
		-webkit-border-radius: 15px;
	}
	
	/*问题标签*/
	.question-title {
		margin-bottom: 20px;
	}
	
	.question-answer {
		margin-left: 23px;
	}
	
	.question-button {
		margin-top: 20px;
		float: right;
	}
	
	/*患者图片上传*/
	/deep/ .upload-patient-case-image {
		.upload-box {
			margin-left: 30px;
		}
	}

</style>
