<template>
	<div class="">
		<el-dialog
			v-loading="listLoading"
			:visible.sync="isActiveAddQuestionDialogs"
			:before-close="handleCancel"
			width="640px">
			<span slot="title" style="font-size: 14px;color: #333;font-weight: 700;">添加试题</span>
			<el-form :model="ruleForm"
							 :rules="rules"
							 ref="ruleForm"
							 size="mini"
							 label-position="top"
							 label-width="100px"
							 class="ruleForm">
				<el-form-item label="题型" prop="questionType" class="from-item-third">
					<el-radio-group v-model="ruleForm.questionType" @change="handleChoiceQuestionType" :disabled="isEditStatus">
						<el-radio v-for="(item, index) in questionTypeList"
											:key="index"
											:label="index"
											style="color: #777">{{item}}
						</el-radio>
					</el-radio-group>
				</el-form-item>
				
				<el-form-item label="题干" prop="questionStem" class="from-item-third">
					<el-input v-model="ruleForm.questionStem" class="from-item-third" placeholder="请输入题干"></el-input>
				</el-form-item>
				
				
				<!--题型单选, 多选, 单选+简答 时显示-->
				<el-form-item label="选项" prop="questionChoice" class="from-item-third" v-show="[0, 1, 5].includes(ruleForm.questionType)">
					<i class="el-icon-circle-plus from-item-question-add-icon" @click="handleAddChoiceQuestion"></i>
					<div style="margin-top: -10px"></div>
					<div v-for="(item, index) in ruleForm.questionChoiceList" :key="index"
							 style="display: flex; justify-content: space-between; align-items: center; margin-top: 10px">
						<el-input v-model="ruleForm.questionChoiceList[index]"
											class="from-item-third"
											placeholder="请输入选项">{{item}}
						</el-input>
						<i class="el-icon-remove from-item-question-sub-icon" @click="handleSubChoiceQuestion(index)"
							 style="height: 24px; margin-left: 20px"></i>
					</div>
				
				</el-form-item>
				
				<!--题型图片 时显示-->
				<el-form-item label="数量" prop="count" class="from-item-third" v-show="2 === ruleForm.questionType">
					<div style="margin-top: 0px"></div>
					<el-input v-model.number.trim="ruleForm.count" class="from-item-third" placeholder="请输入数量" type="number"></el-input>
					<i class="el-icon-warning" style="color: #e6a23c"></i><span
					style="color: #777;margin-left: 3px">此处设置最大图片上传数</span>
				</el-form-item>
				
				<!--题型填空 时显示-->
				<el-form-item label="内容区" prop="content" class="from-item-third" v-show="4 === ruleForm.questionType">
					<el-input v-model="ruleForm.content" class="from-item-third" placeholder="请输入内容" type="textarea"
										:rows="3"></el-input>
					<i class="el-icon-warning" style="color: #e6a23c"></i><span
					style="color: #777;margin-left: 3px">此处配置{}代表可填区域</span>
				</el-form-item>
				
				<el-form-item label="排序" prop="sort" class="from-item-third">
					<el-input v-model.number.trim="ruleForm.sort" class="from-item-third" type="number" placeholder="请输入排序"></el-input>
				</el-form-item>
				
				<el-form-item label="状态" prop="status" class="from-item-third">
					<el-radio-group v-model="ruleForm.status">
						<el-radio :label="1">启用</el-radio>
						<el-radio :label="0">禁用</el-radio>
					</el-radio-group>
				</el-form-item>
			
			</el-form>
			
			
			<div slot="footer" class="dialog-footer">
				<el-button
					size="mini"
					@click="handleCancel">
					<span style="font-size: 12px">取 消</span>
				</el-button>
				<el-button
					style="margin-left: 20px;background-color: #2362FB; border-color: #2362FB"
					size="mini"
					type="primary"
					@click="submitForm('ruleForm')">
					<!--<i class="el-icon-edit" style="font-size: 13px"/>-->
					<span style="font-size: 12px">保 存</span>
				</el-button>
			
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {putQuestionSave, putQuestionUpdateOptions} from '@/api/cdm/cdm-healthFile.js';

export default {
  name: "AddQuestionDialogs",
  props: {
    isActiveAddQuestionDialogs: {
      type: Boolean,
      default: false
    },
    questionType: { // 对应数组index ['单选', '多选', '图片', '简答', '填空', '单选+简答']
      type: String,
			default: '单选'
		},
    editFormData: {
      type: Object,
			default: () => {}
		},
    isEditStatus: {
      type: Boolean,
			default: false,
		}
  },
  created () {

  },
  data () {
    const checkQuestionChoice = (rule, value, callback) => {
      if (![0, 1, 5].includes(this.ruleForm.questionType)) return callback()
      if (!this.ruleForm.questionChoiceList.length) {
        callback(new Error('请增加选项'))
      } else if (!this.ruleForm.questionChoiceList[0].length) {
        callback(new Error('请输入选项内容'))
      } else {
        callback()
      }
    }
    const checkQuestionCount = (rule, value, callback) => {
      if (this.ruleForm.questionType !== 2) return callback()
			if (!value) {
        callback(new Error('请输入最大图片数量(最小1,最大5)'))
			} else if (value <=5 && value >= 1 ) {
        callback()
			}
      	else {
        console.log(value)
        callback(new Error('请输入最大图片数量(最小1,最大5)'))
			}
    }
    const checkQuestionContent = (rule, value, callback) => {
      if (this.ruleForm.questionType !== 4) return callback()
      if (!value) {
        callback(new Error('请输入内容'))
      } else {
        callback()
      }
		}
    const questionTypeList = ['单选', '多选', '图片', '简答', '填空', '单选+简答']
    const questionType = questionTypeList.indexOf(this.questionType)
		
		// 如果传入编辑
		let ruleForm = {}
		if (this.isEditStatus) {
      const options_value =  this.editFormData.options_value.map((item,index,arr) => item.name)
		  ruleForm = {
        questionType,
        questionStem: this.editFormData.title || '',
        questionChoiceList: options_value || [''],
        count: this.editFormData.img_count || '',
        content: this.editFormData.content || '',
        sort: this.editFormData.sort,
        status: this.editFormData.status
		  }
		} else {
      ruleForm = {
        questionType,
        questionStem: '',	// 题干
        questionChoiceList: [''],
        count: '',
        content: '',
        sort: '',
        status: 1,
      }
    }
    return {
      listLoading: false,
      questionTypeList,
      ruleForm,
      rules: {
        questionType: [
          { required: true, message: '请选择问题类型', trigger: 'blur' },
        ],
        questionStem: [
          { required: true, message: '请输入题干', trigger: 'blur' },
        ],
        questionChoice: [
          { validator: checkQuestionChoice, message: '请输入选项内容', trigger: 'blur' },
        ],
        count: [
          { validator: checkQuestionCount, message: '请输入最大图片数量(最小1,最大5)', trigger: 'blur' },
          // { type: 'number', required: true, trigger: 'blur' }
        ],
        content: [
          { validator: checkQuestionContent, message: '请输入内容', trigger: 'blur' },
          // { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' },
          { type: 'number', message: '必须是数字', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
      },
      patientDetail: {}
    }
  },
  methods: {
    _putQuestionSave(data) {
      this.listLoading = true
      putQuestionSave(data).then(response => {
        let data = response
        if (data.code == 200) {
          this.$message({
            showClose: true,
            message: '添加成功',
            type: 'success'
          })
          this.$emit('getList')
					setTimeout(() => {
            this.handleConfirm()
					})
        } else {
          this.$message({
            showClose: true,
            message: data.message,
            type: 'error'
          })
				}
        this.listLoading = false
      }).catch(error => {
        console.log(error)
        this.$message({
          showClose: true,
          message: '网络错误',
          type: 'success'
        })
        this.listLoading = false
      })
    },
		_putQuestionUpdateOptions (id, data) {
      this.listLoading = true
      putQuestionUpdateOptions(id, data).then(response => {
        let data = response
				if (data.code ==200) {
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          })
          this.$emit('getList')
          setTimeout(() => {
            this.handleConfirm()
          })
				} else {
          this.$message({
            showClose: true,
            message: data.message,
            type: 'error'
          })
				}
        this.listLoading = false
			}).catch(error => {
        console.log(error)
        this.$message({
          showClose: true,
          message: '网络错误',
          type: 'success'
        })
        this.listLoading = false
			})
		},
    handleChoiceQuestionType (value) {
      switch (value) {
        case 0:
        case 1:
        case 5:
          console.log(value)
          break
        case 2:
          console.log('222')
          break
        case 3:
          console.log('3333')
          break
        case 4:
          console.log(444)
          break
      }
    },
    handleAddChoiceQuestion () {
      if (this.ruleForm.questionChoiceList.length > 10) {
        this.$message({
          message: '最多只能增加10条选项',
          type: 'warning'
        })
				return
      }
      this.ruleForm.questionChoiceList.push('')
    },
    handleSubChoiceQuestion () {
      this.ruleForm.questionChoiceList.pop()
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        let typeNum = this.ruleForm.questionType + 1
				let data = {
          type: typeNum.toString(),
          title: this.ruleForm.questionStem,	// 题干
          sort: this.ruleForm.sort,
          status: this.ruleForm.status
        }
        if (valid) {
          switch (this.ruleForm.questionType) {
            case 0:
            case 1:
            case 5:
              this.ruleForm.questionChoiceList = this.ruleForm.questionChoiceList.filter(item => item )
              data.options = this.ruleForm.questionChoiceList
              this.isEditStatus ? this._putQuestionUpdateOptions( this.editFormData.id ,data) : this._putQuestionSave(data)
              break
            case 2:
              data.img_count = this.ruleForm.count
              this.isEditStatus ? this._putQuestionUpdateOptions( this.editFormData.id ,data) : this._putQuestionSave(data)
              break
            case 3:
              this.isEditStatus ? this._putQuestionUpdateOptions( this.editFormData.id ,data) : this._putQuestionSave(data)
              break
            case 4:
              data.content = this.ruleForm.content
              this.isEditStatus ? this._putQuestionUpdateOptions( this.editFormData.id ,data) : this._putQuestionSave(data)
              break
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    resetForm(formName) {
      this.$emit("update:isEditStatus", false)
      this.$refs[formName].resetFields();
    },
    handleConfirm () {
      this.resetForm('ruleForm')
			this.ruleForm.questionChoiceList = ['']
      this.$emit("update:isActiveAddQuestionDialogs", false);
    },
    handleCancel (e) {
      this.resetForm('ruleForm')
      this.$emit("update:isActiveAddQuestionDialogs", false);
    }
  }
}
</script>

<style scoped lang="scss">
	.mgl-20 {
		margin-left: 20px;
	}
	
	.mgr-20 {
		margin-right: 20px;
	}
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	
	/*弹出框表单配置*/
	/deep/ .el-form-item__label {
		padding-bottom: 0;
	}
	
	/deep/ .el-form-item__content {
		width: 280px;
	}
	
	.from-item-second {
		margin-left: 0;
		margin-right: 20px;
	}
	
	/deep/ .from-item-third {
		.el-form-item__content {
			width: 590px;
		}
	}
	
	/deep/ .from-item-forth {
		.el-form-item__content {
			width: 550px;
		}
	}
	
	
	/deep/ .el-cascader {
		width: 280px;
	}
	
	/deep/ .el-select {
		width: 280px !important;
	}
	
	/deep/ .el-date-editor {
		width: 280px !important;
	}
	
	/*select 宽度配置*/
	/deep/ .el-select {
		width: 85px;
	}
	
	
	/*加减icon样式位置*/
	.from-item-question-add-icon {
		color: #999;
		position: absolute;
		top: -21px;
		left: 50px;
		
		&:hover {
			color: #555;
			cursor: pointer;
		}
	}
	
	.from-item-question-sub-icon {
		color: #999;
		padding-top: 4px;
		&:hover {
			color: #555;
			cursor: pointer;
		}
	}
</style>
