/*
*慢病管理-健康档案接口
*/

import request from '@/utils/request'

/*
	*********题库********
*/


// 试题列表
export function getQuestionOptionsList(params) {
  return request({
    url: '/admin/question/optionsList',
    method: 'GET',
    params
  })
}

// 添加试题
export function putQuestionSave(data) {
  return request({
    url: '/admin/question/save',
    method: 'POST',
    data
  })
}

// 更新试题状态
export function putQuestionOptionsStatus(id, data) {
  return request({
    url: `/admin/question/optionsStatus/${id}`,
    method: 'PUT',
    data
  })
}

// 更新试题
export function putQuestionUpdateOptions(id, data) {
  return request({
    url: `/admin/question/updateOptions/${id}`,
    method: 'PUT',
    data
  })
}

// 删除试题
export function deleteQuestionDelete(ids, data) {
  return request({
    url: `/admin/question/delete?ids=${ids}`,
    method: 'DELETE',
    data
  })
}


/*
	*********试卷库********
*/

//获取试题列表
export function getTestQuestions(query) {
	return request({
		url: '/admin/question/optionsList',
		method: 'get',
		params: query
	})
}

//获取试卷库列表
export function getTestPaper(query) {
	return request({
		url: '/admin/question/list',
		method: 'get',
		params: query
	})
}

//添加试卷库
export function addTestPaper(query) {
	return request({
		url: '/admin/question/addQuestion',
		method: 'post',
		data: query
	})
}

//试卷库详情
export function getQuestionInfo(id) {
	return request({
		url: `/admin/question/info/${id}`,
		method: 'get'

	})
}

//修改试卷库
export function undataQuestion(query, id) {
	return request({
		url: `/admin/question/update/${id}`,
		method: 'put',
		params: query
	})
}

//删除试题
export function deleteQuestion(id) {
	return request({
		url: `/admin/question/deleteQuestion/${id}`,
		method: 'delete'
	})
}

//修改试卷状态
export function updataStatus(id,query) {
	return request({
		url: `/admin/question/questionStatus/${id}`,
		method: 'put',
		data: query
	})
}
